<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Mail - ' + data.id"  size="xl" @hidden="closeForm()" @close="closeForm()" no-close-on-backdrop>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Casella">
            <b-form-select v-model="data.mailboxes_id" :options="mailboxes" value-field="id" text-field="val" @change="saveFieldData('mailboxes_id', $event)" />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Da">
            <b-form-input v-model="data.from" @change="saveFieldData('from', $event)" />
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Cartella">
            <b-form-select v-model="data.mailboxesfolders_id" :options="folders" value-field="id" text-field="val" @change="saveFieldData('mailboxesfolders_id', $event)" />
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Archivia">
            <b-form-select v-model="data.archivefolder" :options="archivefolders" value-field="id" text-field="val" @change="saveFieldData('archivefolder', $event)" />
          </b-form-group>
        </b-col>
        <b-col lg="1">
          <b-form-group label="Archivia ID">
            <b-form-input type="number" v-model="data.archiveid" :disabled="data.archivefolder === null" @change="saveFieldData('archiveid', $event)" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group label="A">
            <b-form-input v-model="data.to" @change="saveFieldData('to', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Cc">
            <b-form-input v-model="data.cc" @change="saveFieldData('cc', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Ccn">
            <b-form-input v-model="data.bcc" :disabled="data.mailboxesfolders_id === 1" @change="saveFieldData('bcc', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="data.date" @change="saveFieldData('date', $event)" />
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Time">
            <b-form-input type="time" v-model="data.time" @change="saveFieldData('time', $event)" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="9">
          <b-form-group label="Oggetto">
            <b-form-input v-model="data.subject" @change="saveFieldData('subject', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Template">
            <b-form-select v-model="data.templates_id" :options="templates.filter(x => x.mailboxes.includes(data.mailboxes_id))" value-field="id" text-field="val" @change="saveFieldData('templates_id', $event)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <customeditor v-model="data.body" :height="winHeight - 620 < 400 ? 400 : (winHeight - 620)" :showToolbar="true" @input="saveFieldData('body', $event)"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Allegati">
            <b-dropdown class="mr-2 mb-2" size="sm" v-for="(item, index) of data.attachments" :key="index" :text="item">
              <b-dropdown-item @click="deleteAttachments(item)">Elimina</b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal v-model="showUpload" title="Carica File" hide-footer>
            <b-form-file @change="fileChange"/>
          </b-modal>
          <b-btn variant="success" class="mr-2" @click="saveDataMailfake()">Finto invio</b-btn>
          <b-btn variant="primary" @click="showUpload = true">Carica Allegati</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customeditor from '../customeditor.vue';
export default {
  name: "mailnewcheat",
  components: {
    customeditor
  },
  data() {
    return {
      show: false,
      showUpload: false,
      data: null,
      mailboxes: [],
      templates: [],
      folders: [{
          id: 1,
          val: "Ricevute"
      }, {
          id: 3,
          val: "Inviate"
      }],
      archivefolders: [{
        id: null,
        val: "Non archiviare"
      },{
        id: "companies",
        val: "Aziende"
      }, {
        id: "invoices",
        val: "Fatture"
      },{ 
        id: "orders",
        val: "Commesse"
      },{
        id: "quotes",
        val: "Preventivi"
      },{
        id: "contracts",
        val: "Contratti"
      },{
        id: "audits",
        val: "Audits"
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadMailboxes(){
      this.openHttp("mails/mailcheat/mailboxestemplates", {}).then((result) => {
        this.mailboxes = result.mailboxes;
        this.templates = result.templates;
      })
    },
    loadData(dataFrom = { id: 0 }){
      this.loadMailboxes();
      this.openHttp("mails/mailcheat/mail/", dataFrom).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveFieldData(field, val){
      this.saveHttp("mails/mailcheat/mail/save", { id: this.data.id, field: field, val: val }).then((result) => {
        if (result !== null) this.loadData(this.data);
      })
    },
    saveDataMailfake(){
      this.saveHttp("mails/mailcheat/mail/send", { id: this.data.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    fileChange(event){
      var reader = new FileReader();
      if (event.target.files != null){
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = ()=> {
          this.saveHttp("mails/mailcheat/attachment/save", { id: this.data.id, filename: event.target.files[0].name, content: reader.result }).then((result) => {
            if (result !== null){
              this.showUpload = false;
              this.loadData(this.data);
            }
          })
        }
      } else {
        reader.readAsDataURL(event.dataTransfer.files[0]);
        reader.onload = ()=> {
          this.saveHttp("mails/mailcheat/attachment/save", { id: this.data.id, filename: event.dataTransfer.files[0].name, content: reader.result }).then((result) => {
            if ( result !== null ){
              this.showUpload = false;
              this.loadData(this.data);
            }
          })
        }
      }
    },
    deleteAttachments(filename){
      this.deleteHttp("mails/mailcheat/attachment/delete", { id: this.data.id, filename: filename }).then((result) => {
        if ( result !== null ) this.loadData(this.data);
      })
    },
    closeForm(){
      this.show = false;
      this.showUpload = false;
      this.data = null;
      this.$emit("update");
    }
  }
}
</script>