<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Anno prezzo - ' + data.costyear.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Anno">
                        <b-form-input v-model="data.costyear.year" type="number" min="0" step="1" :disabled="data.costyear.locked"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Fase">
                        <b-form-select v-model="data.costyear.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val" :disabled="data.costyear.locked"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Preventivo">
                        <b-form-input v-model="data.costyear.cost" type="number" min="0" :disabled="data.costyear.locked"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Audit">
                        <b-form-select v-model="data.costyear.audits_id" :options="addNullCombo(data.audits, 'Vuoto')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="Norme">
                        <b-btn variant="primary" size="sm" v-if="data.contractsstandards.length != data.costyear.standards.length" @click="data.costyear.standards.push({ id: 0, contractsstandards_id: data.contractsstandards.filter(x => !data.costyear.standards.map(y => y.contractsstandards_id).includes(x.id))[0].id, accreditated: null, riskslevels_id: null, mandaystable: null, mandaysreduced: null, mandaysonsite: null, note: null, locked: false })">Nuovo</b-btn>
                        <b-table no-border-collapse class="mt-2" :items="data.costyear.standards" :fields="fields.standards">
                            <template v-slot:cell(contractsstandards_id)="row">
                                <b-form-select v-model="row.item.contractsstandards_id" :options="data.contractsstandards.filter(x => row.item.id > 0 ? (x.id === row.item.contractsstandards_id) : true)" value-field="id" text-field="val" :disabled="data.costyear.locked"/>
                            </template>
                            <template v-slot:cell(mandaystable)="row">
                                <b-form-input v-model="row.item.mandaystable" type="number" min="0" step="0.0001" :disabled="data.costyear.locked"/>
                            </template>
                            <template v-slot:cell(releases_id)="row">
                                <b-form-select v-model="row.item.contractsstandardsreleases_id" :options="data.releases.filter(x => x.contractsstandards_id === row.item.contractsstandards_id)" value-field="id" text-field="val" :disabled="data.costyear.locked"/>
                            </template>
                            <template v-slot:cell(mandaysreduced)="row">
                                <b-form-input v-model="row.item.mandaysreduced" type="number" min="0" step="0.0001" :disabled="data.costyear.locked"/>
                            </template>
                            <template v-slot:cell(mandaysonsite)="row">
                                <b-form-input v-model="row.item.mandaysonsite" type="number" min="0" step="0.0001" :disabled="data.costyear.locked"/>
                            </template>
                            <template v-slot:cell(note)="row">
                                <b-form-textarea v-model="row.item.note" rows="2" max-rows="10" size="sm" :disabled="data.costyear.locked"/>
                            </template>
                            <template v-slot:cell(id)="row">
                                <b-btn variant="danger" size="sm" v-if="data.costyear.locked === false" @click="data.costyear.standards.splice(data.costyear.standards.findIndex(x => x.contractsstandards_id === row.item.contractsstandards_id), 1)">Elimina</b-btn>
                            </template>
                        </b-table>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.costyear.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractcost",
    data() {
        return {
            show: false,
            data: null,
            fields: {
                standards: [{
                    key: "contractsstandards_id",
                    label: "Norma",
                    thStyle: { width: "20%" },
                },{
                    key: "releases_id",
                    label: "Rev",
                    thStyle: { width: "10%" }
                },{
                    key: "mandaystable",
                    label: "GG tabella",
                    thStyle: { width: "10%" }
                },{
                    key: "mandaysreduced",
                    label: "GG ridotti",
                    thStyle: { width: "10%" }
                },{
                    key: "mandaysonsite",
                    label: "GG onsite",
                    thStyle: { width: "10%" }
                },{
                    key: "note",
                    label: "Note",
                    thStyle: { width: "30%" }
                },{
                    key: "id",
                    label: "",
                    thStyle: { width: "5%" }
                }]
            },
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/costyear/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/costyear/save", this.data.costyear).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/costyear/delete", { id: this.data.costyear.id, contracts_id: this.data.costyear.contracts_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>