import axios from "axios";
import dictionary from "./dictionary.json";
import store from "../store/index";

export default {
    methods: {
        async openHttp (functionName, parameters){
            let dataHttp = {
                token: this.$store.getters.getToken
            };
            if ( parameters !== null ) dataHttp.data = parameters;
            const response = await axios.post("/api/" + functionName, dataHttp).then(response => {
                if (response.data.result === true){
                    return response.data.data;                    
                } else {
                    this.$bvToast.toast(response.data.error, { title: "Gestionale - Errore", variant: "warning", appendToast: true });
                    throw response.data.error;
                }
            }).catch(error => {
                this.$bvToast.toast(error.message, { title: "Gestionale - Errore", variant: "warning", appendToast: true });
                return null;
            })
            return response;
        },
        async saveHttp (functionName, parameters){
            console.log(functionName)
            const response = await axios.post("/api/" + functionName, { token: this.$store.getters.getToken, data: parameters }).then(response => {
                if (response.data.result === true){
                    this.$bvToast.toast("Salvato", { title: "Gestionale", variant: "success", autoHideDelay: 1500, appendToast: true })
                    return response.data.data;
                } else {  
                    this.$bvToast.toast(response.data.error, { title: "Gestionale - Errore", variant: "warning", appendToast: true });
                    throw response.data.error;
                }
            }).catch(error => {
                this.$bvToast.toast(error.message, { title: "Gestionale - Errore", variant: "danger", appendToast: true });
                return null;
            })
            return response;
        },
        async deleteHttp (functionName, parameters){
            const response = await axios.post("/api/" + functionName, { token: this.$store.getters.getToken, data: parameters }).then(response => {
                if (response.data.result === true){
                    this.$bvToast.toast("Eliminato", { title: "Gestionale", variant: "danger", autoHideDelay: 1500, appendToast: true })
                    return response.data.data;
                } else {                   
                    this.$bvToast.toast(response.data.error, { title: "Gestionale - Errore", variant: "warning", appendToast: true });
                    throw response.data.error;
                }
            }).catch(error => {
                this.$bvToast.toast(error.message, { title: "Gestionale - Errore", variant: "warning", appendToast: true });
                return null;
            })
            return response;
        },
        addNullCombo( fromData, label = "Tutti/e - All" ){
            return [{ id: null, val: label }].concat(fromData);
        },
        convertCombo( fromData, fromValues ){
            return fromValues.map(x => fromData.find(y => y.id === x) );
        },
        updateCombo( fromValues ){
            this.$forceUpdate();
            return fromValues.map(x => x.id);
        },
        convertComboSimple( fromData, fromValue ){
          return fromData.find(x => x.id === fromValue);
        },
        updateComboSimple( fromValue ){
            this.$forceUpdate();
            return fromValue != null ? fromValue.id : null;
        },
        convertDate( fromValue ){
            const toDate = new Date(fromValue);
            return toDate;
        },
        translate(word){
            if (dictionary.findIndex(x => x.w === word) > -1 && dictionary.find(x => x.w === word).l.findIndex( j => j.i == store.state.lang) > -1){
                let translated = dictionary.find(x => x.w === word).l.find( j => j.i == store.state.lang).v;
                return translated[0].toUpperCase() + translated.substring(1);
            } else if (word === ""){
                return "";
            } else {
                return word + "___ Err";
            }
        }
    }
}
