<template>
    <div>
        <accreditation ref="accreditation" @update="loadAccreditations()"/>
        <auditerror ref="auditerror" @update="loadAuditsErrors()"/>
        <certificationbody ref="certificationbody" @update="loadCertificationbodies()"/>
        <contracterror ref="contracterror" @update="loadContractsErrors()"/>
        <checklisterror ref="checklisterror" @update="loadChecklistsErrors()"/>
        <contractsparameterstype ref="contractsparameterstype" @update="loadContractsParametersTypes()"/>
        <auditsparameterstype ref="auditsparameterstype" @update="loadAuditsParametersTypes()"/>
        <nace ref="nace" @update="loadNaces()"/>
        <technicalarea ref="technicalarea" @update="loadTechnicalareas()"/>
        <standard ref="standard" @update="loadStandards()"/>
        <step ref="step" @update="loadSteps()"/>
        <question ref="question" @update="loadQuestions()"/>
        <law ref="law" @update="loadLaws()"/>
        <internalauditquestion ref="internalauditquestion" @update="loadInternalaudits()"/>
        <requirement ref="requirement" @update="loadRequirements()"/>
        <b-card no-body>
            <b-tabs card>
                <b-tab title="Enti" active>
                    <b-btn class="mr-2" variant="primary" @click="loadCertificationbodies">Aggiorna</b-btn>
                    <b-btn variant="success" @click="$refs.certificationbody.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="certificationbodies.items.data" :fields="certificationbodies.fields" :busy="certificationbodies.isBusy" :no-local-sorting="true" :sort-by.sync="certificationbodies.search.sortby" :sort-desc.sync="certificationbodies.search.sortdesc" @sort-changed="sortingChangeCertificationbodies" @row-clicked="$refs.certificationbody.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="certificationbodies.isBusy" :currentpage="certificationbodies.search.page" :total="certificationbodies.items.total" @updateCurrentPage="certificationbodies.search.page = $event" @loadData="loadCertificationbodies"/>
                </b-tab>
                <b-tab title="Accreditamenti">
                    <b-modal v-model="accreditations.showFilter" title="Filtra">
                        <b-form-group label="Norma">
                            <b-form-select v-model="accreditations.tempFilter.standards_id" :options="addNullCombo(accreditations.filtersMenu.standards)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <b-form-group label="Ente">
                            <b-form-select v-model="accreditations.tempFilter.certificationbodies_id" :options="addNullCombo(accreditations.filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetAccreditations">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetAccreditations">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadAccreditations">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="filterOpenAccreditations">Filtra</b-btn>
                    <b-btn variant="success" @click="$refs.accreditation.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassAccreditation" :items="accreditations.items.data" :fields="accreditations.fields" :busy="accreditations.isBusy" :no-local-sorting="true" :sort-by.sync="accreditations.search.sortby" :sort-desc.sync="accreditations.search.sortdesc" @sort-changed="sortingChangeAccreditations" @row-clicked="$refs.accreditation.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="accreditations.isBusy" :currentpage="accreditations.search.page" :total="accreditations.items.total" @updateCurrentPage="accreditations.search.page = $event" @loadData="loadAccreditations"/>
                </b-tab>
                <b-tab title="Norme">
                    <b-modal v-model="standards.showFilter" title="Filtra">
                        <b-form-group label="Stato">
                            <b-form-select v-model="standards.tempFilter.active" :options="addNullCombo([{id: true, val: 'Attivo'}, {id: false, val: 'Non Attivo'}])" value-field="id" text-field="val"/>
                        </b-form-group>
                        <b-form-group label="Norma">
                            <b-form-input v-model="standards.tempFilter.standard"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetStandards">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetStandards">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadStandards">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="filterOpenStandards">Filtra</b-btn>
                    <b-btn variant="success" @click="$refs.standard.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="standards.items.data" :fields="standards.fields" :busy="standards.isBusy" :no-local-sorting="true" :sort-by.sync="standards.search.sortby" :sort-desc.sync="standards.search.sortdesc" @sort-changed="sortingChangeStandards" @row-clicked="$refs.standard.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="standards.isBusy" :currentpage="standards.search.page" :total="standards.items.total" @updateCurrentPage="standards.search.page = $event" @loadData="loadStandards"/>
                </b-tab>
                <b-tab title="Steps">
                    <b-modal v-model="steps.showFilter" title="Filtra">
                        <b-form-group label="Norma">
                            <b-form-select v-model="steps.tempFilter.standards_id" :options="addNullCombo(steps.filtersMenu.standards)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <b-form-group label="Ente">
                            <b-form-select v-model="steps.tempFilter.certificationbodies_id" :options="addNullCombo(steps.filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetSteps">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetSteps">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadSteps">Aggiorna</b-btn>
                    <b-btn variant="secondary" @click="filterOpenSteps">Filtra</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="steps.items.data" :fields="steps.fields" :busy="steps.isBusy" :no-local-sorting="true" :sort-by.sync="steps.search.sortby" :sort-desc.sync="steps.search.sortdesc" @sort-changed="sortingChangeSteps" @row-clicked="$refs.step.loadData($event.certificationbodies_id, $event.standards_id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                        <template v-slot:cell(auditsphasespoints)="row">
                            <div v-for="(item, index) of row.value" :key="index">
                                <strong>{{ item.auditsphase }}: </strong> <span>{{ item.points }}</span>
                            </div>
                        </template>
                    </b-table>
                    <tablebottom :busy="steps.isBusy" :currentpage="steps.search.page" :total="steps.items.total" @updateCurrentPage="steps.search.page = $event" @loadData="loadSteps"/>
                </b-tab>
                <b-tab title="NACE">
                    <b-modal v-model="naces.showFilter" title="Filtra">
                        <b-form-group label="Norma">
                            <b-form-select v-model="naces.tempFilter.standards_id" :options="addNullCombo(naces.filtersMenu.standards)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetNaces">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetNaces">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadNaces">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="filterOpenNaces">Filtra</b-btn>
                    <b-btn variant="success" @click="$refs.nace.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="naces.items.data" :fields="naces.fields" :busy="naces.isBusy" :no-local-sorting="true" :sort-by.sync="naces.search.sortby" :sort-desc.sync="naces.search.sortdesc" @sort-changed="sortingChangeNaces" @row-clicked="$refs.nace.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="naces.isBusy" :currentpage="naces.search.page" :total="naces.items.total" @updateCurrentPage="naces.search.page = $event" @loadData="loadNaces"/>
                </b-tab>
                <b-tab title="Aree tecniche">
                    <b-modal v-model="technicalareas.showFilter" title="Filtra">
                        <b-form-group label="Ente">
                            <b-form-select v-model="technicalareas.tempFilter.certificationbodies_id" :options="addNullCombo(technicalareas.filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <b-form-group label="Norma">
                            <b-form-select v-model="technicalareas.tempFilter.standards_id" :options="addNullCombo(technicalareas.filtersMenu.standards)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetTechnicalareas">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetTechnicalareas">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadTechnicalareas()">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="filterOpenTechnicalareas()">Filtra</b-btn>
                    <b-btn variant="success" @click="$refs.technicalarea.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="technicalareas.items.data" :fields="technicalareas.fields" :busy="technicalareas.isBusy" :no-local-sorting="true" :sort-by.sync="technicalareas.search.sortby" :sort-desc.sync="technicalareas.search.sortdesc" @sort-changed="sortingChangeTechnicalareas" @row-clicked="$refs.technicalarea.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="technicalareas.isBusy" :currentpage="technicalareas.search.page" :total="technicalareas.items.total" @updateCurrentPage="technicalareas.search.page = $event" @loadData="loadTechnicalareas"/>
                </b-tab>
                <b-tab title="Parametri contratto">
                    <b-modal v-model="contractsparameterstypes.showFilter" title="Filtra">
                        <b-form-group label="Parametro">
                            <b-form-input v-model="contractsparameterstypes.tempFilter.parameter"/>
                        </b-form-group>
                        <b-form-group label="Stato">
                            <b-form-select v-model="contractsparameterstypes.tempFilter.active" :options="addNullCombo([{id: true, val: 'Attivo'}, {id: false, val: 'Non Attivo'}])" value-field="id" text-field="val"/>
                        </b-form-group>
                        <b-form-group label="SQL codice">
                            <b-form-input v-model="contractsparameterstypes.tempFilter.sqlcode"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetContractsParametersTypes">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetContractsParametersTypes">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadContractsParametersTypes">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="contractsparameterstypes.showFilter = true">Filtra</b-btn>
                    <b-btn variant="success" @click="$refs.contractsparameterstype.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="contractsparameterstypes.items.data" :fields="contractsparameterstypes.fields" :busy="contractsparameterstypes.isBusy" :no-local-sorting="true" :sort-by.sync="contractsparameterstypes.search.sortby" :sort-desc.sync="contractsparameterstypes.search.sortdesc" @sort-changed="sortingContractsParametersTypes" @row-clicked="$refs.contractsparameterstype.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="contractsparameterstypes.isBusy" :currentpage="contractsparameterstypes.search.page" :total="contractsparameterstypes.items.total" @updateCurrentPage="contractsparameterstypes.search.page = $event" @loadData="loadContractsParametersTypes()"/>
                </b-tab>
                <b-tab title="Parametri audit">
                    <b-modal v-model="auditsparameterstypes.showFilter" title="Filtra">
                        <b-form-group label="Parametro">
                            <b-form-input v-model="auditsparameterstypes.tempFilter.parameter"/>
                        </b-form-group>
                        <b-form-group label="SQL codice">
                            <b-form-input v-model="auditsparameterstypes.tempFilter.sqlcode"/>
                        </b-form-group>                        
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetAuditsParametersTypes()">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetAuditsParametersTypes()">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadAuditsParametersTypes()">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="auditsparameterstypes.showFilter = true">Filtra</b-btn>
                    <b-btn variant="success" @click="$refs.auditsparameterstype.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="auditsparameterstypes.items.data" :fields="auditsparameterstypes.fields" :busy="auditsparameterstypes.isBusy" :no-local-sorting="true" :sort-by.sync="auditsparameterstypes.search.sortby" :sort-desc.sync="auditsparameterstypes.search.sortdesc" @sort-changed="sortingAuditsParametersTypes" @row-clicked="$refs.auditsparameterstype.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="auditsparameterstypes.isBusy" :currentpage="auditsparameterstypes.search.page" :total="auditsparameterstypes.items.total" @updateCurrentPage="auditsparameterstypes.search.page = $event" @loadData="loadAuditsParametersTypes()"/>
                </b-tab>
                <b-tab title="Domande">
                    <b-modal v-if="questions.showFilter" v-model="questions.showFilter" title="Filtra">
                    <b-form-group label="Id">
                        <b-form-input type="number" v-model="questions.tempFilter.id"/>
                    </b-form-group>
                    <b-form-group label="Domanda">
                        <b-form-input v-model="questions.tempFilter.question"/>
                    </b-form-group>
                    <b-form-group label="Paragrafo">
                        <b-form-select v-model="questions.tempFilter.auditsquestionsparagraphs_id" :options="addNullCombo(questions.filtersMenu.paragraphs)" value-field="id" text-field="val"/>
                    </b-form-group>
                    <b-form-group label="Norma">
                        <b-form-select v-model="questions.tempFilter.standards_id" :options="addNullCombo(questions.filtersMenu.standards)" value-field="id" text-field="val"/>
                    </b-form-group>
                    <b-form-group label="Multi NACE">
                        <b-form-select v-model="questions.tempFilter.multinace" :options="addNullCombo([{id: true, val: 'Multiplo'},{id: false, val: 'Singolo'}])" value-field="id" text-field="val"/>
                    </b-form-group>
                    <b-form-group label="Multi sito">
                        <b-form-select v-model="questions.tempFilter.multisite" :options="addNullCombo([{id: true, val: 'Multiplo'},{id: false, val: 'Singolo'}])" value-field="id" text-field="val"/>
                    </b-form-group>
                    <template v-slot:modal-footer>
                        <div class="w-100">
                            <b-btn variant="primary" class="mr-2" @click="filterSetQuestions">Filtra</b-btn>
                            <b-btn variant="warning" @click="filterResetQuestions">Reset</b-btn>
                        </div>
                    </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadQuestions">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="filterOpenQuestions">Filtra</b-btn>
                    <b-btn class="mr-2" variant="success" @click="$refs.question.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="questions.items.data" :fields="questions.fields" :busy="questions.isBusy" :no-local-sorting="true" :sort-by.sync="questions.search.sortby" :sort-desc.sync="questions.search.sortdesc" @sort-changed="sortingChangeQuestions" @row-clicked="$refs.question.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                        <template v-slot:cell(points)="row">
                            <div v-for="(item, index) of row.value" :key="index">
                                <nobr><strong>{{ item.standard }}</strong> {{ item.points }}</nobr>
                            </div>
                        </template>
                        <template v-slot:cell(exclusions)="row">
                            <div v-for="(item, index) of row.value" :key="index">
                                <nobr><strong>{{ item.standard }}</strong> {{ item.exclusions }}</nobr>
                            </div>
                        </template>
                    </b-table>
                    <tablebottom :busy="questions.isBusy" :currentpage="questions.search.page" :total="questions.items.total" @updateCurrentPage="questions.search.page = $event" @loadData="loadQuestions"/>
                </b-tab>
                <b-tab title="Leggi">
                    <b-modal v-model="laws.showFilter" title="Filtra">
                        <b-form-group label="Legge">
                            <b-form-input v-model="laws.tempFilter.law"/>
                        </b-form-group>
                        <b-form-group label="Norma">
                            <b-form-select v-model="laws.tempFilter.standards_id" :options="addNullCombo(laws.filtersMenu.standards)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <b-form-group label="IAF">
                            <b-form-select v-model="laws.tempFilter.nacesiafcodes_id" :options="addNullCombo(laws.filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <b-form-group label="Stato">
                            <b-form-select v-model="laws.tempFilter.states_id" :options="addNullCombo(laws.filtersMenu.states)" value-field="id" text-field="val"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetLaws">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetLaws">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadLaws">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="filterOpenLaws">Filtra</b-btn>
                    <b-btn class="mr-2" variant="success" @click="$refs.law.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="laws.items.data" :fields="laws.fields" :busy="laws.isBusy" :no-local-sorting="true" :sort-by.sync="laws.search.sortby" :sort-desc.sync="laws.search.sortdesc" @sort-changed="sortingChangeLaws" @row-clicked="$refs.law.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="laws.isBusy" :currentpage="laws.search.page" :total="laws.items.total" @updateCurrentPage="laws.search.page = $event" @loadData="loadLaws"/>
                </b-tab>
                <b-tab title="Errori Contratti">
                    <b-modal v-model="contractserrors.showFilter" title="Filtra">
                        <b-form-group label="Errore">
                            <b-form-input v-model="contractserrors.tempFilter.errorname"/>
                        </b-form-group>
                        <b-form-group label="SQL">
                            <b-form-input v-model="contractserrors.tempFilter.errorsql"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetContractsErrors">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetContractsErrors">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadContractsErrors()">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="contractserrors.showfilter = true">Filtra</b-btn>
                    <b-btn class="mr-2" variant="success" @click="$refs.contracterror.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="contractserrors.items.data" :fields="contractserrors.fields" :busy="contractserrors.isBusy" :no-local-sorting="true" :sort-by.sync="contractserrors.search.sortby" :sort-desc.sync="contractserrors.search.sortdesc" @sort-changed="sortingChangeContractsErrors" @row-clicked="$refs.contracterror.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="contractserrors.isBusy" :currentpage="contractserrors.search.page" :total="contractserrors.items.total" @updateCurrentPage="contractserrors.search.page = $event" @loadData="loadContractsErrors()"/>
                </b-tab>
                <b-tab title="Errori Audits">
                    <b-modal v-model="auditserrors.showFilter" title="Filtra">
                        <b-form-group label="Errore">
                            <b-form-input v-model="auditserrors.tempFilter.errorname"/>
                        </b-form-group>
                        <b-form-group label="SQL">
                            <b-form-input v-model="auditserrors.tempFilter.errsql"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetAuditsErrors">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetAuditsErrors">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadAuditsErrors()">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="auditserrors.showfilter = true">Filtra</b-btn>
                    <b-btn class="mr-2" variant="success" @click="$refs.auditerror.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="auditserrors.items.data" :fields="auditserrors.fields" :busy="auditserrors.isBusy" :no-local-sorting="true" :sort-by.sync="auditserrors.search.sortby" :sort-desc.sync="auditserrors.search.sortdesc" @sort-changed="sortingChangeAuditsErrors" @row-clicked="$refs.auditerror.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="auditserrors.isBusy" :currentpage="auditserrors.search.page" :total="auditserrors.items.total" @updateCurrentPage="auditserrors.search.page = $event" @loadData="loadAuditsErrors()"/>
                </b-tab>
                <b-tab title="Errori Checklists">
                    <b-modal v-model="checklistserrors.showFilter" title="Filtra">
                        <b-form-group label="Errore">
                            <b-form-input v-model="checklistserrors.tempFilter.errorname"/>
                        </b-form-group>
                        <b-form-group label="SQL">
                            <b-form-input v-model="checklistserrors.tempFilter.errsql"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetChecklistsErrors">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetChecklistsErrors">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadChecklistsErrors()">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="checklistserrors.showfilter = true">Filtra</b-btn>
                    <b-btn class="mr-2" variant="success" @click="$refs.checklisterror.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="checklistserrors.items.data" :fields="checklistserrors.fields" :busy="checklistserrors.isBusy" :no-local-sorting="true" :sort-by.sync="checklistserrors.search.sortby" :sort-desc.sync="checklistserrors.search.sortdesc" @sort-changed="sortingChangeChecklistsErrors" @row-clicked="$refs.checklisterror.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="checklistserrors.isBusy" :currentpage="checklistserrors.search.page" :total="checklistserrors.items.total" @updateCurrentPage="checklistserrors.search.page = $event" @loadData="loadChecklistsErrors()"/>
                </b-tab>



                <b-tab title="Domande Audit interno">
                    <b-modal v-model="checklistserrors.showFilter" title="Filtra">
                        <b-form-group label="Errore">
                            <b-form-input v-model="internalaudits.tempFilter.title"/>
                        </b-form-group>
                        <b-form-group label="SQL">
                            <b-form-input v-model="internalaudits.tempFilter.description"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetInternalaudits">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetInternalaudits">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadInternalaudits()">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="internalaudits.showfilter = true">Filtra</b-btn>
                    <b-btn class="mr-2" variant="success" @click="$refs.internalauditquestion.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="internalaudits.items.data" :fields="internalaudits.fields" :busy="internalaudits.isBusy" :no-local-sorting="true" :sort-by.sync="internalaudits.search.sortby" :sort-desc.sync="internalaudits.search.sortdesc" @sort-changed="sortingChangeInternalaudits" @row-clicked="$refs.internalauditquestion.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="internalaudits.isBusy" :currentpage="internalaudits.search.page" :total="internalaudits.items.total" @updateCurrentPage="internalaudits.search.page = $event" @loadData="loadInternalaudits()"/>
                </b-tab>
                <b-tab title="Requisiti">
                    <b-modal v-model="requirements.showFilter" title="Filtra">
                        <b-form-group label="Errore">
                            <b-form-input v-model="requirements.tempFilter.requirement"/>
                        </b-form-group>
                        <template v-slot:modal-footer>
                            <div class="w-100">
                                <b-btn variant="primary" class="mr-2" @click="filterSetRequirements">Filtra</b-btn>
                                <b-btn variant="warning" @click="filterResetRequirements">Reset</b-btn>
                            </div>
                        </template>
                    </b-modal>
                    <b-btn class="mr-2" variant="primary" @click="loadRequirements()">Aggiorna</b-btn>
                    <b-btn class="mr-2" variant="secondary" @click="requirements.showfilter = true">Filtra</b-btn>
                    <b-btn class="mr-2" variant="success" @click="$refs.requirement.loadData(0)">Nuovo</b-btn>
                    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="requirements.items.data" :fields="requirements.fields" :busy="requirements.isBusy" :no-local-sorting="true" :sort-by.sync="requirements.search.sortby" :sort-desc.sync="requirements.search.sortdesc" @sort-changed="sortingChangeRequirements" @row-clicked="$refs.requirement.loadData($event.id)">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary">
                                <b-spinner class="align-middle"/>
                            </div>
                        </template>
                        <template v-slot:empty>Non ci sono risultati</template>
                    </b-table>
                    <tablebottom :busy="requirements.isBusy" :currentpage="requirements.search.page" :total="requirements.items.total" @updateCurrentPage="requirements.search.page = $event" @loadData="loadRequirements()"/>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import certificationbody from './certificationbody.vue';
import standard from './standard.vue';
import accreditation from './accreditation.vue';
import step from './step.vue';
import nace from './nace.vue';
import contractsparameterstype from "./contractsparameterstype.vue";
import auditsparameterstype from "./auditsparameterstype.vue";
import question from './question.vue';
import law from './law.vue';
import technicalarea from './technicalarea.vue';
import contracterror from "./contracterror.vue";
import auditerror from "./auditerror.vue";
import checklisterror from "./checklisterror.vue";
import internalauditquestion from "./internalauditquestion.vue";
import requirement from "./requirement.vue";

export default {
    name: "settingsaudits",
    components : { certificationbody, standard, accreditation, step, nace, contractsparameterstype, auditsparameterstype, question, law, technicalarea, contracterror, auditerror, checklisterror, internalauditquestion, requirement },
    data() {
        return {
            certificationbodies: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: { },
                    sortby: 'certificationbody',
                    sortdesc: false,
                },
                fields: [{
                    key: 'certificationbody',
                    label: 'Ente',
                    sortable: true,
                },{
                    key: 'active',
                    label: 'Stato',
                    sortable: true,
                    formatter: value => {
                        return value === true ? 'Attivo' : 'Non attivo'
                    },
                },{
                    key: 'company',
                    label: 'Azienda in anagrafica',
                    sortable: true,
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            standards: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        active: true,
                        standard: null,
                        certificationbodies_id: null,
                    },
                    sortby: 'standard',
                    sortdesc: false,
                },
                tempFilter: {
                    active: true,
                    standard: null,
                    certificationbodies_id: null,
                },
                filtersMenu: {
                    certificationbodies:[],
                },
                fields: [{
                    key: 'standard',
                    label: 'Norma',
                    sortable: true,
                },{
                    key: 'active',
                    label: 'Stato',
                    sortable: true,
                    formatter: value => {
                        return value === true ? "Attivo" : "Non attivo"
                    },
                },{
                    key: 'area',
                    label: 'Area',
                    sortable: true,
                },{
                    key: 'riskslevels',
                    label: 'Rischio',
                },{
                    key: 'points',
                    label: 'Punti',
                },{
                    key: 'exclusions',
                    label: 'Esclusioni',
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            accreditations: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        standards_id: null,
                        certificationbodies_id: null,
                    },
                    sortby: 'certificationbody',
                    sortdesc: false,
                },
                tempFilter: {
                    standards_id: null,
                    certificationbodies_id: null,
                },
                filtersMenu: {
                    standards:[],
                    certificationbodies: [],
                },
                activeStandardsCombo: [{
                    id: true,
                    val: "Attive",
                },{
                    id: null,
                    val: "Tutte",
                }],
                fields: [{
                    key: 'certificationbody',
                    label: 'Ente',
                    sortable: true,
                },{
                    key: 'standard',
                    label: 'Norma',
                    sortable: true,
                },{
                    key: 'accreditationbody',
                    label: 'Accreditamento',
                    sortable: true,
                },{
                    key: 'naces',
                    label: 'NACE',
                    sortable: true,
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            steps: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        standards_id: null,
                        certificationbodies_id: null,
                    },
                    sortby: 'certificationbody',
                    sortdesc: false,
                },
                tempFilter: {
                    standards_id: null,
                    certificationbodies_id: null,
                },
                filtersMenu: {
                    standards:[],
                    certificationbodies: [],
                },
                fields: [{
                    key: 'standard',
                    label: 'Norma',
                    sortable: true,
                },{
                    key: 'certificationbody',
                    label: 'Ente',
                    sortable: true,
                },{
                    key: 'auditsphasespoints',
                    label: 'Punti',
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            naces: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        standards_id: null,
                    },
                    sortby: 'nacecomplete',
                    sortdesc: false,
                },
                tempFilter: {
                    standards_id: null,
                },
                filtersMenu: {
                    standards:[],
                },
                fields: [{
                    key: 'nacecomplete',
                    label: 'NACE',
                    sortable: true,
                },{
                    key: 'description',
                    label: 'Descrizione'
                },{
                    key: 'iaf',
                    label: 'iaf'
                },{
                    key: 'standards',
                    label: 'Norme'
                },{
                    key: 'resources',
                    label: 'Risorse'
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            technicalareas: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        certificationbodies_id: null,
                        standards_id: null,
                    },
                    sortby: 'technicalarea',
                    sortdesc: false,
                },
                tempFilter: {
                    certificationbodies_id: null,
                    standards_id: null,
                },
                filtersMenu: {
                    certificationbodies: [],
                    standards:[],
                },
                fields: [{
                    key: 'technicalarea',
                    label: 'Area',
                    sortable: true,
                },{
                    key: 'certificationbody',
                    label: 'Ente',
                    sortable: true
                },{
                    key: 'standard',
                    label: 'Norma',
                    sortable: true
                },{
                    key: 'naces',
                    label: 'Naces'
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            contractsparameterstypes: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        parameter: null,
                        active: true,
                        sqlcode: null,
                    },
                    sortby: 'nacecomplete',
                    sortdesc: false
                },
                tempFilter: {
                    parameter: null,
                    active: true,
                    sqlcode: null
                },
                fields: [{
                    key: 'parametertype',
                    label: 'Parametro',
                    sortable: true
                },{
                    key: 'active',
                    label: 'Stato',
                    sortable: true,
                    formatter: (value) => {
                        return value === true ? "Attivo" : "Non attivo";
                    },
                },{
                    key: 'parameterorder',
                    label: 'Ordine',
                    sortable: true,
                },{
                    key: 'parametersql',
                    label: 'Valido'
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            auditsparameterstypes: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        parameter: null,
                        sqlcode: null,
                    },
                    sortby: 'nacecomplete',
                    sortdesc: false
                },
                tempFilter: {
                    parameter: null,
                    sqlcode: null
                },
                fields: [{
                    key: 'parametertype',
                    label: 'Parametro',
                    sortable: true
                },{
                    key: 'parameterorder',
                    label: 'Ordine',
                    sortable: true,
                },{
                    key: 'sqlenable',
                    label: 'Valido'
                },{
                    key: "certificationbodies",
                    label: "Enti"
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            questions: {
                isBusy: true,
                showFilter: false,
                search: {
                page: 1,
                    filters: {
                        id: null,
                        question: null,
                        standards_id: null,
                        auditsquestionsparagraphs_id: null,
                        multisite: null,
                        multinace: null,
                    },
                    sortby: null,
                    sortdesc: false,
                },
                tempFilter: {
                    id: null,
                    question: null,
                    standards_id: null,
                    auditsquestionsparagraphs_id: null,
                    multisite: null,
                    multinace: null
                },
                filtersMenu: {
                    standards:[],
                    auditsquestionsparagraphs:[],
                },
                fields: [{
                    key: 'paragraph',
                    label: 'Paragrafo',
                    sortable: true,
                },{
                    key: 'orderquestion',
                    label: 'Ordine',
                    sortable: true,
                },{
                    key: 'question',
                    label: 'Domanda',
                    formatter: (value, key, item) => {
                        return item.id + ") " + ( item.question.findIndex(x => x.id === 3) > -1 ? item.question.find(x => x.id === 3).val : '???' );
                    },
                },{
                    key: 'subquestions',
                    label: 'Sottodomande',
                    formatter: (value) => {
                        return value.map(x => x.id + ') ' + (x.question.findIndex(x => x.id === 3) > -1 ? x.question.find(x => x.id === 3).val : '???') + ' - ' + x.formattype + ' ' + [ (x.needtranslate === true ? 'Traduzione' : null), (x.nummin > 1 ? 'Evid.' + x.nummin : null)].join(" ") ).join('; ');
                    },
                },{
                    key: 'points',
                    label: 'Punti',
                },{
                    key: 'exclusions',
                    label: 'Esclusioni',
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            laws: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        law: null,
                        standards_id: null,
                        states_id: null,
                        nacesiafcodes_id: null,
                    },
                    sortby: 'law',
                    sortdesc: false
                },
                tempFilter: {
                    law: null,
                    standards_id: null,
                    states_id: null,
                    nacesiafcodes_id: null,
                },
                filtersMenu: {
                    standards: [],
                    states: [],
                    nacesiafcodes: [],
                },
                fields: [{
                    key: 'law',
                    label: 'Legge',
                    sortable: true,
                },{
                    key: "standards",
                    label: "Standards",
                },{
                    key: 'iafcodes',
                    label: 'IAF',
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            contractserrors: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        errorname: null,
                        errorsql: null,
                    },
                    sortby: 'errorname',
                    sortdesc: false
                },
                tempFilter: {
                    errorname: null,
                    errorsql: null,
                },
                fields: [{
                    key: "errorname",
                    label: "Errore",
                    sortable: true
                },{
                    key: "errorsql",
                    label: "SQL"
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            auditserrors: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        errorname: null,
                        errsql: null,
                    },
                    sortby: 'errorname',
                    sortdesc: false
                },
                tempFilter: {
                    errorname: null,
                    errsql: null,
                },
                fields: [{
                    key: "errorname",
                    label: "Errore",
                    sortable: true
                },{
                    key: "errorsql",
                    label: "SQL"
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            checklistserrors: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        errorname: null,
                        errsql: null,
                    },
                    sortby: 'errorname',
                    sortdesc: false
                },
                tempFilter: {
                    errorname: null,
                    errsql: null,
                },
                fields: [{
                    key: "id",
                    label: "#",
                    sortable: true
                },{
                    key: "errorname",
                    label: "Errore",
                    sortable: true
                },{
                    key: "errorsql",
                    label: "SQL"
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            internalaudits: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        title: null,
                        description: null,
                    },
                    sortby: 'title',
                    sortdesc: false
                },
                tempFilter: {
                    title: null,
                    description: null,
                },
                fields: [{
                    key: "title",
                    label: "Question",
                    sortable: true
                },{
                    key: "questionorder",
                    label: "Order",
                    sortable: true
                },{
                    key: "standardspoints",
                    label: "Punti"
                }],
                items: {
                    total: 0,
                    data: [],
                }
            },
            requirements: {
                isBusy: true,
                showFilter: false,
                search: {
                    page: 1,
                    filters: {
                        requirement: null,
                    },
                    sortby: 'requirement',
                    sortdesc: false
                },
                tempFilter: {
                    requirement: null,
                },
                fields: [{
                    key: "requirement",
                    label: "Requisito",
                    sortable: true
                },{
                    key: "codesql",
                    label: "SQL",
                }],
                items: {
                    total: 0,
                    data: [],
                }
            }
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData(){
            //this.loadCertificationbodies();
            this.loadStandards();
            //this.loadAccreditations();
            //this.loadSteps();
            //this.loadNaces();
            this.loadContractsParametersTypes();
            this.loadAuditsParametersTypes();
            //this.loadTechnicalareas();
            this.loadQuestions();
            //this.loadLaws();
            this.loadContractsErrors();
            this.loadAuditsErrors();
            //this.loadChecklistsErrors();
            this.loadInternalaudits();
            this.loadRequirements();
        },
        loadCertificationbodies(){
            this.certificationbodies.isBusy = true;
            this.openHttp("settings/audits/certificationbodies", this.certificationbodies.search ).then((result) => {
                this.certificationbodies.isBusy = false;
                this.certificationbodies.items = result;
            })
        },
        sortingChangeCertificationbodies(ctx) {
            this.certificationbodies.search.sortby = ctx.sortBy;
            this.certificationbodies.search.sortdesc = ctx.sortDesc;
            this.loadCertificationbodies();
        },
        loadStandards(){
            this.standards.isBusy = true;
            this.openHttp("settings/audits/standards", this.standards.search ).then((result) => {
                this.standards.isBusy = false;
                this.standards.items = result;
            })
        },
        filterOpenStandards(){
            this.standards.showFilter = true;
        },
        filterSetStandards(){
            this.standards.showFilter = false;
            this.standards.search.filters = this.standards.tempFilter;
            this.standards.search.page = 1;
            this.loadStandards();
        },
        filterResetStandards(){
            this.standards.tempFilter = {
                active: true,
                standard: null,
                certificationbodies_id: null,
            };
            this.filterSetStandards();
        },
        sortingChangeStandards(ctx) {
            this.standards.search.sortby = ctx.sortBy;
            this.standards.search.sortdesc = ctx.sortDesc;
            this.loadStandards();
        },
        rowClassAccreditation ( item ){
            if ( item !== null ) return (item.accreditationbody === null ? "table-warning" : "");
        },
        loadAccreditations(){
            this.accreditations.isBusy = true;
            this.openHttp("settings/audits/accreditations", this.accreditations.search ).then((result) => {
                this.accreditations.isBusy = false;
                this.accreditations.items = result;
            })
        },
        filterOpenAccreditations(){
            this.openHttp("settings/audits/filters", null ).then((result) => {
                this.accreditations.filtersMenu = result;
                this.accreditations.showFilter = true;                
            })
        },
        filterSetAccreditations(){
            this.accreditations.showFilter = false;
            this.accreditations.search.filters = this.accreditations.tempFilter;
            this.accreditations.search.page = 1;
            this.loadAccreditations();
        },
        filterResetAccreditations(){
            this.accreditations.tempFilter = {
                standards_id: null,
                certificationbodies_id: null,
            };
            this.filterSetAccreditations();
        },
        sortingChangeAccreditations(ctx) {
            this.accreditations.search.sortby = ctx.sortBy;
            this.accreditations.search.sortdesc = ctx.sortDesc;
            this.loadAccreditations();
        },
        loadSteps(){
            this.steps.isBusy = true;
            this.openHttp("settings/audits/steps", this.steps.search ).then((result) => {
                this.steps.items = result;
                this.steps.isBusy = false;
            })
        },
        filterOpenSteps(){
            this.openHttp("settings/audits/filters", null ).then((result) => {
                this.steps.filtersMenu = result;
                this.steps.showFilter = true;
            })
        },
        filterSetSteps(){
            this.steps.showFilter = false;
            this.steps.search.filters = this.steps.tempFilter;
            this.steps.search.page = 1;
            this.loadSteps();
        },
        filterResetSteps(){
            this.steps.tempFilter = {
                standards_id: null,
                certificationbodies_id: null,                
            };
            this.filterSetSteps();
        },
        sortingChangeSteps(ctx) {
            this.steps.search.sortby = ctx.sortBy;
            this.steps.search.sortdesc = ctx.sortDesc;
            this.loadSteps();
        },
        loadContractsParametersTypes(){
            this.contractsparameterstypes.isBusy = true;
            this.openHttp("settings/audits/contractsparameterstypes", this.contractsparameterstypes.search ).then((result) => {
                this.contractsparameterstypes.items = result;
                this.contractsparameterstypes.isBusy = false;
            })
        },
        filterSetContractsParametersTypes(){
            this.contractsparameterstypes.showFilter = false;
            this.contractsparameterstypes.search.filters = this.contractsparameterstypes.tempFilter;
            this.contractsparameterstypes.search.page = 1;
            this.loadContractsParametersTypes();
        },
        filterResetContractsParametersTypes(){
            this.contractsparameterstypes.tempFilter = {
                parameter: null,
                active: true,
                sqlcode: null,
            };
            this.filterSetContractsParametersTypes();
        },
        sortingContractsParametersTypes(ctx) {
            this.contractsparameterstypes.search.sortby = ctx.sortBy;
            this.contractsparameterstypes.search.sortdesc = ctx.sortDesc;
            this.loadContractsParametersTypes();
        },


        loadAuditsParametersTypes(){
            this.auditsparameterstypes.isBusy = true;
            this.openHttp("settings/audits/auditsparameterstypes", this.auditsparameterstypes.search ).then((result) => {
                this.auditsparameterstypes.items = result;
                this.auditsparameterstypes.isBusy = false;
            })
        },
        filterSetAuditsParametersTypes(){
            this.auditsparameterstypes.showFilter = false;
            this.auditsparameterstypes.search.filters = this.contractsparameterstypes.tempFilter;
            this.auditsparameterstypes.search.page = 1;
            this.loadAuditsParametersTypes();
        },
        filterResetAuditsParametersTypes(){
            this.auditsparameterstypes.tempFilter = {
                parameter: null,
                sqlcode: null,
            };
            this.filterSetAuditsParametersTypes();
        },
        sortingAuditsParametersTypes(ctx) {
            this.auditsparameterstypes.search.sortby = ctx.sortBy;
            this.auditsparameterstypes.search.sortdesc = ctx.sortDesc;
            this.loadAuditsParametersTypes();
        },        
        loadNaces(){
            this.naces.isBusy = true;
            this.openHttp("settings/audits/naces", this.naces.search ).then((result) => {
                this.naces.items = result;
                this.naces.isBusy = false;
            })
        },
        filterOpenNaces(){
            this.openHttp("settings/audits/filters", null).then((result) => {
                this.naces.filtersMenu = result;
                this.naces.showFilter = true;
            })
        },
        filterSetNaces(){
            this.naces.showFilter = false;
            this.naces.search.filters = this.naces.tempFilter;
            this.naces.search.page = 1;
            this.loadNaces();
        },
        filterResetNaces(){
            this.naces.tempFilter = {
                standards_id: null
            };
            this.filterSetNaces();
        },
        sortingChangeNaces(ctx) {
            this.naces.search.sortby = ctx.sortBy;
            this.naces.search.sortdesc = ctx.sortDesc;
            this.loadNaces();
        },
        loadTechnicalareas(){
            this.technicalareas.isBusy = true;
            this.openHttp("settings/audits/technicalareas", this.technicalareas.search ).then((result) => {
                this.technicalareas.items = result;
                this.technicalareas.isBusy = false;
            })
        },
        filterOpenTechnicalareas(){
            this.openHttp("settings/audits/filters", null ).then((result) => {
                this.technicalareas.filtersMenu = result;
                this.technicalareas.showFilter = true;
            })
        },
        filterSetTechnicalareas(){
            this.technicalareas.showFilter = false;
            this.technicalareas.search.filters = this.technicalareas.tempFilter;
            this.technicalareas.search.page = 1;
            this.loadTechnicalareas();
        },
        filterResetTechnicalareas(){
            this.technicalareas.tempFilter = {
                standards_id: null,
                certificationbodies_id: null
            };
            this.filterSetNaces();
        },
        sortingChangeTechnicalareas(ctx) {
            this.technicalareas.search.sortby = ctx.sortBy;
            this.technicalareas.search.sortdesc = ctx.sortDesc;
            this.loadTechnicalareas();
        },
        loadQuestions(){
            this.questions.isBusy = true;
            this.openHttp("settings/audits/questions", this.questions.search ).then((result) => {
                this.questions.items = result;
                this.questions.isBusy = false;
            })
        },
        filterOpenQuestions(){
            this.openHttp("settings/audits/filters", null ).then((result) => {
                this.questions.filtersMenu = result;
                this.questions.showFilter = true;
            })
        },
        filterSetQuestions(){
            this.questions.showFilter = false;
            this.questions.search.filters = this.questions.tempFilter;
            this.questions.search.page = 1;
            this.loadQuestions();
        },
        filterResetQuestions(){
            this.questions.tempFilter = {
                id: null,
                question: null,
                standards_id: null,
                auditsquestionsparagraphs_id: null,
                multisite: null,
                multinace: null
            };
            this.filterSetQuestions();
        },
        sortingChangeQuestions(ctx) {
            this.questions.search.sortby = ctx.sortBy;
            this.questions.search.sortdesc = ctx.sortDesc;
            this.loadQuestions();
        },
        loadLaws(){
            this.laws.isBusy = true;
            this.openHttp("settings/audits/laws", this.laws.search).then((result) => {
                this.laws.items = result;
                this.laws.isBusy = false;
            })
        },
        filterOpenLaws(){
            this.openHttp("settings/audits/filters", {}).then((result) => {
                this.laws.filtersMenu = result;
                this.laws.showFilter = true;
            })
        },
        filterSetLaws(){
            this.laws.search.page = 1;
            this.laws.showFilter = false;
            this.lawssearch.filters = this.laws.tempFilter;
            this.loadLaws();
        },
        filterResetLaws(){
            this.laws.tempFilter = {
                law: null,
                standards_id: null,
                nacesiafcodes_id: null,
                states_id: null,
            }
            this.filterSetLaws();
        },
        sortingChangeLaws(ctx) {
            this.laws.search.sortby = ctx.sortBy;
            this.laws.search.sortdesc = ctx.sortDesc;
            this.loadLaws();
        },
        loadContractsErrors(){
            this.contractserrors.isBusy = true;
            this.openHttp("settings/audits/contractserrors", this.contractserrors.search).then((result) => {
                this.contractserrors.items = result;
                this.contractserrors.isBusy = false;
            })
        },
        filterSetContractsErrors(){
            this.contractserrors.search.page = 1;
            this.contractserrors.showFilter = false;
            this.contractserrors.filters = this.contractserrors.tempFilter;
            this.loadContractsErrors();
        },
        filterResetContractsErrors(){
            this.contractserrors.tempFilter = {
                errorname: null,
                errorsql: null,
            }
            this.filterSetContractsErrors();
        },
        sortingChangeContractsErrors(ctx) {
            this.contractserrors.search.sortby = ctx.sortBy;
            this.contractserrors.search.sortdesc = ctx.sortDesc;
            this.loadContractsErrors();
        },
        loadAuditsErrors(){
            this.auditserrors.isBusy = true;
            this.openHttp("settings/audits/auditserrors", this.auditserrors.search).then((result) => {
                this.auditserrors.items = result;
                this.auditserrors.isBusy = false;
            })
        },
        filterSetAuditsErrors(){
            this.auditserrors.search.page = 1;
            this.auditserrors.showFilter = false;
            this.auditserrors.filters = this.auditserrors.tempFilter;
            this.loadAuditsErrors();
        },
        filterResetAuditsErrors(){
            this.auditserrors.tempFilter = {
                errorname: null,
                errsql: null,
            }
            this.filterSetAuditsErrors();
        },
        sortingChangeAuditsErrors(ctx) {
            this.auditserrors.search.sortby = ctx.sortBy;
            this.auditserrors.search.sortdesc = ctx.sortDesc;
            this.loadAuditsErrors();
        },
        loadChecklistsErrors(){
            this.checklistserrors.isBusy = true;
            this.openHttp("settings/audits/checklistserrors", this.checklistserrors.search).then((result) => {
                this.checklistserrors.items = result;
                this.checklistserrors.isBusy = false;
            })
        },
        filterSetChecklistsErrors(){
            this.checklistserrors.search.page = 1;
            this.checklistserrors.showFilter = false;
            this.checklistserrors.filters = this.checklistserrors.tempFilter;
            this.loadChecklistsErrors();
        },
        filterResetChecklistsErrors(){
            this.checklistserrors.tempFilter = {
                errorname: null,
                errsql: null,
            }
            this.filterSetChecklistsErrors();
        },
        sortingChangeChecklistsErrors(ctx) {
            this.checklistserrors.search.sortby = ctx.sortBy;
            this.checklistserrors.search.sortdesc = ctx.sortDesc;
            this.loadChecklistsErrors();
        },





        loadInternalaudits(){
            this.internalaudits.isBusy = true;
            this.openHttp("settings/audits/internalaudits", this.internalaudits.search).then((result) => {
                this.internalaudits.items = result;
                this.internalaudits.isBusy = false;
            })
        },
        filterSetInternalaudits(){
            this.internalaudits.search.page = 1;
            this.internalaudits.showFilter = false;
            this.internalaudits.filters = this.internalaudits.tempFilter;
            this.loadInternalaudits();
        },
        filterResetInternalaudits(){
            this.internalaudits.tempFilter = {
                errorname: null,
                errsql: null,
            }
            this.filterSetInternalaudits();
        },
        sortingChangeInternalaudits(ctx) {
            this.internalaudits.search.sortby = ctx.sortBy;
            this.internalaudits.search.sortdesc = ctx.sortDesc;
            this.loadInternalaudits();
        },


        loadRequirements(){
            this.requirements.isBusy = true;
            this.openHttp("settings/audits/requirements", this.requirements.search).then((result) => {
                this.requirements.items = result;
                this.requirements.isBusy = false;
            })
        },
        filterSetRequirements(){
            this.requirements.search.page = 1;
            this.requirements.showFilter = false;
            this.requirements.filters = this.requirements.tempFilter;
            this.loadRequirements();
        },
        filterResetRequirements(){
            this.requirements.tempFilter = {
                requirement: null,
            }
            this.filterSetRequirements();
        },
        sortingChangeRequirements(ctx) {
            this.requirements.search.sortby = ctx.sortBy;
            this.requirements.search.sortdesc = ctx.sortDesc;
            this.loadRequirements();
        }
    }
}
</script>