<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Requisito - ' + data.requirement.id" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Requisito">
        <b-form-input v-model="data.requirement.requirement"/>
      </b-form-group>
      <b-form-group label="Metodo">
        <b-form-textarea v-model="data.requirement.evaluationmethod" rows="5"/>
      </b-form-group>
      <b-form-group label="SQL">
        <b-form-textarea v-model="data.requirement.codesql" rows="5" max-rows="10"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "requirement",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData(id){
      this.openHttp("settings/audits/requirement/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("settings/audits/requirement/save", this.data.requirement).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    },
  },
}
</script>
